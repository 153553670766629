import { useState, useEffect } from "react";
import amplitude from 'amplitude-js';

import styles from "./PlanResultProgress.module.scss";
import { useFeatureValue } from "@growthbook/growthbook-react";
import strings from "../../../assets/localization/strings.jsx";
import CustomCheckbox from "../../UI/checkbox/Checkbox";
import {Swiper, SwiperSlide} from "swiper/react";
import {Autoplay, Pagination} from "swiper";
import LazyImage from "../../UI/lazyImage/LazyImage";


const ProgressBar = (props) => {
    const { tick, minTick, maxTick, title, theme } = props;

    const inBetween = tick >= minTick && tick < maxTick
    const titleClass = inBetween ? "activeLabel" : tick < minTick ? "inactiveLabel" : "finishedLabel"
    const progressBarClass = `${styles.progressBar} ${styles[`${theme}ProgressBar`]}`
    const progressValue = inBetween ? ((tick-minTick) / 100) : (tick < minTick ? 0 : 1)
    const checkboxData = {
        "checked": true,
        "disabled": true
    }

    return (
        <>
            <div className={styles.labelRow}>
                <p className={styles[titleClass]}>{title}</p>
                { inBetween
                    ? (<p className={styles.activePercent}>{(tick-minTick)}%</p>)
                    : (tick < minTick)
                        ? (<p className={styles.activePercent}>0%</p>)
                        : (<CustomCheckbox data={checkboxData} color={theme} checkCard={()=>{}}/>)}
            </div>
            <progress
                className={progressBarClass}
                value={progressValue}
            />

        </>
    )
}

const PlanResultProgress = (props) => {
    const { data, nextStep, answers } = props;
    const pageInfo = data.settings.planResultProgress
    const themeData = data.themeData
    const value = useFeatureValue("paywall_testing", "fallback");
    const [tick, setTick] = useState(0);
    const [timer, setTimer] = useState();

    useEffect(() => {
        setTimer(setInterval(() => setTick(prev => prev + 1), 30));
        amplitude.getInstance().logEvent(`screen_onboarding_fitness_profile_appeared`);
        return () => {
            setTick(0);
            clearInterval(timer);
        }
    }, [])

    useEffect(() => {
        if(tick === 400) {
            clearInterval(timer);
            setTimeout(async () => {
               // let requestMap = { platform: "web", campaign: data.campaign, ...answers };

                 let requestMap = {};
                 requestMap["platform"] = "web";
                 requestMap["campaign"] = data.campaign;
                 answers.forEach((value, key) => {
                    requestMap[key] = value
                 });

                await fetch("https://1u68st9dui.execute-api.us-east-1.amazonaws.com/dev/create_registration_statistics", {
                    method: "POST",
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(requestMap),
                }).then(async (response) => {
                    console.log("create statistic record result");
                    let resultBody = response.json();
                    await resultBody.then( async (json) => {
                        localStorage.setItem('onboarding_id', json.body.id);
                    });
                });
                amplitude.getInstance().logEvent('onboarding_finish');
                nextStep();
            }, 1000)
        }
    }, [tick])

    return (
        <div className={`${styles.mainContainer} flex-column`}>
            <h1 className={styles.title}>{strings.plan.resultProgress.title}</h1>
            <div className={styles.progress}>
                <ProgressBar
                    tick={tick}
                    minTick={0}
                    maxTick={100}
                    title={strings.plan.result.labels[25]}
                    theme={themeData.checkboxCardColor}
                />
                <ProgressBar
                    tick={tick}
                    minTick={100}
                    maxTick={200}
                    title={strings.plan.result.labels[50]}
                    theme={themeData.checkboxCardColor}
                />
                <ProgressBar
                    tick={tick}
                    minTick={200}
                    maxTick={300}
                    title={strings.plan.result.labels[75]}
                    theme={themeData.checkboxCardColor}
                />
                <ProgressBar
                    tick={tick}
                    minTick={300}
                    maxTick={400}
                    title={strings.plan.result.labels[100]}
                    theme={themeData.checkboxCardColor}
                />
            </div>
            <div className={styles.infoContainer}>
                <div className={styles.infoImage}>
                    <LazyImage
                        className={styles.image}
                        srcWebp="/images/result/trusted.webp"
                        src="/images/result/trusted.png"
                        alt=""
                    />
                </div>
                <div className={styles.infoTitle}>
                    {strings.plan.result.subtitle}
                </div>
            </div>
            <div className={styles.commentsContainer}>
                <Swiper
                    className={styles.greenSwiper}
                    spaceBetween={30}
                    slidesPerView={"auto"}
                    centeredSlides={true}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                    modules={[Autoplay]}
                >
                    { pageInfo.comments.map((comment, i) => (
                        <SwiperSlide key={i} className={styles.swiperSlide}>
                            <div className={styles.commentContainer}>
                                <div className={styles.imageContainer}>
                                    <LazyImage
                                        className={styles.stars}
                                        srcWebp="/images/paywall/stars.webp"
                                        src="/images/paywall/stars.png"
                                        alt=""
                                    />
                                </div>
                                <p>{strings.plan.result.comments[comment.id].subtitle}</p>
                                <div className={`${styles.authorContainer} flex-between`}>
                                    <span>{strings.plan.result.comments[comment.id].author}</span>
                                </div>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </div>
    )
}

export default PlanResultProgress;