import { useEffect, useState } from "react";
import { CognitoUserAttribute } from 'amazon-cognito-identity-js';

import TextInput from "../../UI/textInput/TextInput";
import PasswordInput from "../../UI/passwordInput/PasswordInput";
import UserPool from "./UserPool";

import amplitude from 'amplitude-js';
import styles from "./RegistrationForm.module.scss";
import AppleLogin from "react-apple-login";
import FacebookLogin from 'react-facebook-login';
import { FacebookProvider, LoginButton, useLogin, useFacebook } from 'react-facebook';
import { GoogleOAuthProvider } from "@react-oauth/google"
import { googleLogout, useGoogleLogin } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google';
import AppleSignin from 'react-apple-signin-auth';
import React from 'react';
import axios from "axios";
import updatePaymentEvent from "../../../hooks/facebookCAPI";
import strings from "../../../assets/localization/strings.jsx";

const RegistrationFormSelect = props => {
    const { nextStep, switchOption, setClosedPopup, setWaitingPopup, domain } = props
    const emailInfo = localStorage.getItem('emailInfo')
    const subscriptionId = localStorage.getItem('subscriptionId')
    const [disableButton, setDisableButton] = useState(true)
    const [nickname, setNickname] = useState('')
    const [inputEmail, setInputEmail] = useState(emailInfo)
    const [errorMessage, setErrorMessage] = useState('')
    const [infoMessage, setInfoMessage] = useState('')
    const [ googleUser, setGoogleUser ] = useState(null);
    const { login, status, isLoading, error} = useLogin();
    const { init } = useFacebook();

    function getCookie(name) {
           const matches = document.cookie.match(new RegExp(
             "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
           ));
           return matches ? decodeURIComponent(matches[1]) : undefined;
    }

    useEffect(() => {

        setDisableButton(true)
        amplitude.getInstance().logEvent('screen_onboarding_create_account_appeared');
        return () => {
            setDisableButton(true)
        }
    }, [])

    useEffect(
            () => {
                if (googleUser) {
                   console.log(googleUser);
                    axios
                        .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${googleUser.access_token}`, {
                            headers: {
                                Authorization: `Bearer ${googleUser.access_token}`,
                                Accept: 'application/json'
                            }
                        })
                        .then((res) => {
                        console.log(res.data)
                        const email = res.data.email;
                         //   setProfile(res.data);


                        })
                        .catch((err) => console.log(err));
                }
            },
            [ googleUser ]
        );

    const clickContinueApple = () => {
        setDisableButton(true)
        setWaitingPopup(true)
        amplitude.getInstance().logEvent('button_onboarding_create_account_create_account_tapped');
        const attributeList = [];
        attributeList.push(new CognitoUserAttribute({ Name: 'email', Value: inputEmail.toLowerCase() }));
        UserPool.signUp(inputEmail.toLowerCase(), inputPassword, attributeList, null, (err, data) => {
            if (err) {
                setError()
            } else {
                signUp(data.userSub)
            }
        });
    }

   const appleResponse = async (response) => {
   console.log("appleResponse");
   console.log(response);
    if (!response.error) {
      axios
        .post("/auth", response)
        .then(res => this.setState({ authResponse: res.data }))
        .catch(err => console.log(err));
    }
   };

    const handleFacebookCallback = async (response) => {
        console.log(response);
        if (response?.status === "unknown") {
            console.error('Sorry!', 'Something went wrong with facebook Login.');
         return;
        }

          // console will print following object for you.

       }

    async function handleLogin() {
        const api = await init();


            try {
            const response = await api.login({  scope: 'public_profile,email' });
                    const FB = await api.getFB(); // Get original FB object
                    console.log(response.status);
                    console.log(response);
//                  const response2 = await login({
//                    scope: 'public_profile,email',
//                  });
//
//                  console.log(response2.status);
               } catch (error) {
                 console.log(error.message);
               }
    }

    const clickContinueGmail = () => {
                setDisableButton(true)
                setWaitingPopup(true)
                amplitude.getInstance().logEvent('button_onboarding_create_account_create_account_tapped');
                const attributeList = [];
                attributeList.push(new CognitoUserAttribute({ Name: 'email', Value: inputEmail.toLowerCase() }));
                UserPool.signUp(inputEmail.toLowerCase(), inputPassword, attributeList, null, (err, data) => {
                    if (err) {
                        setError()
                    } else {
                        signUp(data.userSub)
                    }
                });
   }

    const googleLogin = useGoogleLogin({
           onSuccess: (codeResponse) => setGoogleUser(codeResponse),
           onError: (error) => console.log('Login Failed:', error)
       });

   const clickContinueEmail = () => {

    amplitude.getInstance().logEvent('button_onboarding_create_account_create_account_email_tapped');
    switchOption("email");
   }

    const setError = () => {
        amplitude.getInstance().logEvent('button_onboarding_create_account_account_created_failed')
        setWaitingPopup(false)
        setClosedPopup(false)
        setDisableButton(false)
    }

    const signUp = (cognitoUserId) => {
       // check inputFullName
         // send names to facebook capi event for higher event score
         try{
          const eventSystems = ['Subscribe', 'Purchase', 'StartTrial']
           if(inputFullName.includes(" ")){
                            // split to first name and last name
                            const names = inputFullName.split(' ');
                            const firstName = names[0];
                            const lastName = names[1];

                            eventSystems.map(action => {
                                         updatePaymentEvent(action, firstName, lastName, domain)
                            })

           } else{
                   eventSystems.map(action => {
                                    updatePaymentEvent(action, inputFullName, null, domain)
                  })
          }
         } catch(e){
         console.log(e);
         }


        let body = {
            "name": inputFullName,
            "email": inputEmail.toLowerCase(),
            "cognitoUserId": cognitoUserId,
            "authenticator": "cognito",
            "nickname": nickname,
            "platform": "web",
            "amplitude_user_id": amplitude.getInstance().options.userId,
            "subscription_id": subscriptionId,
            "onboarding_id": localStorage.getItem('onboarding_id')
        }
        fetch("https://k1ltj4j168.execute-api.us-east-1.amazonaws.com/dev/create_user_profile", {
            method: "POST",
            body: JSON.stringify(body)
        }).then(async (result) => {
            console.log(result)
            let resultBody = result.json();
             await resultBody.then( async (json) => {
                   console.log(json)
                   localStorage.setItem('profile_id', json.id);
            });
            if(result.status === 200){
                amplitude.getInstance().logEvent('button_onboarding_create_account_account_created')
                setWaitingPopup(false)
                setDisableButton(false)
                nextStep();
            } else {
                setError()
            }
        }).catch(async (e) => {
            setError()
        })
    }

    const responseMessage = (response) => {
            console.log(response);
        };
        const googleErrorMessage = (error) => {
            console.log(error);
        };

    function handleSuccess(response) {
       console.log(response.status);
     }

     function handleError(error) {
       console.log(error);
     }


    return (
        <div className={`${styles.mainContainer} flex-column`}>
            <h1 className={styles.title}>{strings.registration.title}</h1>
            <p className={styles.subtitle}>{strings.registration.subtitle}</p>
            <div className={styles.formContainer}>
                  <AppleSignin
                      /** Auth options passed to AppleID.auth.init() */
                      authOptions={{
                        /** Client ID - eg: 'com.example.com' */
                        clientId: '1.app.everdance',
                        /** Requested scopes, seperated by spaces - eg: 'email name' */
                        scope: 'email name',
                        /** Apple's redirectURI - must be one of the URIs you added to the serviceID - the undocumented trick in apple docs is that you should call auth from a page that is listed as a redirectURI, localhost fails */
                        redirectURI: 'https://test2.everdance.org',
                        /** State string that is returned with the apple response */
                        state: 'state',
                        /** Nonce */
                        nonce: 'nonce',
                        /** Uses popup auth instead of redirection */
                        usePopup: true,
                      }} // REQUIRED
                      /** General props */
                      uiType="dark"
                      /** className */
                      className="apple-auth-btn"
                      /** Removes default style tag */
                      noDefaultStyle={false}
                      /** Allows to change the button's children, eg: for changing the button text */
                      buttonExtraChildren="Continue with Apple"
                      /** Extra controlling props */
                      /** Called upon signin success in case authOptions.usePopup = true -- which means auth is handled client side */
                      onSuccess={(response) => console.log(response)} // default = undefined
                      /** Called upon signin error */
                      onError={(error) => console.error(error)} // default = undefined
                      /** Skips loading the apple script if true */
                      skipScript={false} // default = undefined
                      /** Apple image props */
                      iconProp={{ style: { marginTop: '10px' } }} // default = undefined
                      /** render function - called with all props - can be used to fully customize the UI by rendering your own component  */
                      render={(props) => <button {...props} className={`${styles.optionContainer}`} onClick={props.onClick} >
                                         <img className={styles.buttonImage} src="/images/registration/apple.png" />
                                         <span className={styles.optionText}> {strings.registration.withApple}</span>
                                        </button>}
                    />
                  <AppleLogin
                                 clientId="1.app.everdance"
                                 redirectURI="https://test2.everdance.org"
                                 usePopup={true}
                                 callback={appleResponse}
                                 scope="email name"
                                 responseMode="query"
                                 render={renderProps => (
                                   <button className={`${styles.optionContainer}`} onClick={renderProps.onClick} >
                                           <img className={styles.buttonImage} src="/images/registration/apple.png" />
                                           <span className={styles.optionText}> {strings.registration.withApple}</span>
                                    </button>
                                 )}
                               />
                 <FacebookProvider appId="499580667556769">
                 <button className={`${styles.optionContainer}`} onClick={handleLogin} disabled={isLoading} >
                                    <img className={styles.buttonImage} src="/images/registration/facebook.png" />
                                    <span className={styles.optionText}> {strings.registration.withFB}</span>
                 </button>
                 </FacebookProvider>

                 <GoogleOAuthProvider clientId='1023626244689-jos22lkfke1tvm6qb959tpm3eqrb39a1.apps.googleusercontent.com'>
                     <React.StrictMode>
                       <button className={`${styles.optionContainer}`} onClick={googleLogin} >
                                                        <img className={styles.buttonImage} src="/images/registration/gmail.png" />
                                                        <span className={styles.optionText}> {strings.registration.withGmail}</span>
                     </button>
                     </React.StrictMode>

                   </GoogleOAuthProvider>

                   <button className={`${styles.optionContainer}`} onClick={clickContinueEmail} >
                                                         <img className={styles.buttonImage} src="/images/registration/email.png" />
                                                         <span className={styles.optionText}> {strings.registration.withEmail}</span>
                   </button>

                <div className={styles.errorContainer}>
                    { errorMessage && (<p className={`${styles.defaultMessage} ${styles.errorMessage}`}>{errorMessage}</p>) }
                    { infoMessage && (<p className={`${styles.defaultMessage} ${styles.infoMessage}`}>{infoMessage}</p>) }
                </div>
            </div>

            <div className={`${styles.agreements} flex-column`}>
                <span>{strings.registration.bySigning}</span>
                <div className={`${styles.agreementLinks} flex-row`}>
                    <a href={'https://everdance.app/terms'}>Terms&Service</a>
                    <span>. {strings.registration.view}</span>
                    <a href={'https://everdance.app/policy'}>Privacy Policy</a>
                    <span>.</span>
                </div>
            </div>
        </div>
    )
}

export default RegistrationFormSelect;