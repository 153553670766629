import { useEffect, useState } from 'react';
import RegistrationStepper from "../../components/UI/registrationStepper/RegistrationStepper";
import RegistrationForm from "../../components/createAccount/registrationForm/RegistrationForm";
import RegistrationFormSelect from "../../components/createAccount/registrationForm/RegistrationFormSelect";
import stepsData from "../../core/static/createAccount/registrationSteps.json";
import styles from "./CreateAccount.module.scss";
import RegistrationResult from "../../components/createAccount/registrationResult/RegistrationResult";
import FailedRegistrationPopup from "../../components/UI/failedRegistrationPopup/FailedRegistrationPopup";
import WaitingRegistrationPopup from "../../components/UI/waitingRegistrationPopup/WaitingRegistrationPopup";
import RegistrationOfferList from "../../components/createAccount/registrationOfferList/RegistrationOfferList";
import amplitude from "amplitude-js";
import strings from "../../assets/localization/strings";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { FacebookProvider } from 'react-facebook';

const CreateAccount = () => {
    const [activeStep, setActiveStep] = useState(2);
    const [registrationOption, setRegistrationOption] = useState("all");
    const [stepComponent, setStepComponent] = useState();
    const [closedFailedRegistrationPopup, setClosedFailedRegistrationPopup] = useState(true)
    const [displayWaitingRegistrationPopup, setDisplayWaitingRegistrationPopup] = useState(false)

    const steps = stepsData;
    const domain = localStorage.getItem('domain')

    useEffect(() => {
        setClosedFailedRegistrationPopup(true)
        setDisplayWaitingRegistrationPopup(false)
    }, [])

    const tryAgainAction = () => {
        setClosedFailedRegistrationPopup(true);
    }

    const nextStep = () => {
        setActiveStep((prev) => prev + 1);
    };

    const registerOption = (option) => {
            setRegistrationOption(option);
    };

    const skipOfferStep = () => {
        amplitude.getInstance().logEvent('button_onboarding_skip_tapped');
        nextStep();
    }

    useEffect(() => {
        setStepComponent(findStep(activeStep, registrationOption))
    }, [activeStep, registrationOption])

    const findStep = (value, registrationOption) => {
        switch(value) {
            case 2:
                return  <GoogleOAuthProvider clientId='1023626244689-jos22lkfke1tvm6qb959tpm3eqrb39a1.apps.googleusercontent.com'>
                         {registrationOption === "all"
                         ?  <FacebookProvider appId="499580667556769"><RegistrationFormSelect nextStep={nextStep}  switchOption={registerOption}  setClosedPopup={setClosedFailedRegistrationPopup} setWaitingPopup={setDisplayWaitingRegistrationPopup} domain={domain} /> </FacebookProvider>
                         : <RegistrationForm nextStep={nextStep}  switchOption={registerOption}  setClosedPopup={setClosedFailedRegistrationPopup} setWaitingPopup={setDisplayWaitingRegistrationPopup} domain={domain} /> }
                        </GoogleOAuthProvider>;
            case 3:
                return <RegistrationOfferList nextStep={nextStep} mealPlan={true} />;
            case 4:
                return <RegistrationResult />;
            default:
                return  <GoogleOAuthProvider clientId='1023626244689-jos22lkfke1tvm6qb959tpm3eqrb39a1.apps.googleusercontent.com'>
                                                {registrationOption === "all"
                                                ? <FacebookProvider appId="499580667556769"><RegistrationFormSelect nextStep={nextStep}  switchOption={registerOption}  setClosedPopup={setClosedFailedRegistrationPopup} setWaitingPopup={setDisplayWaitingRegistrationPopup} domain={domain} /></FacebookProvider>
                                                : <RegistrationForm nextStep={nextStep}  switchOption={registerOption}  setClosedPopup={setClosedFailedRegistrationPopup} setWaitingPopup={setDisplayWaitingRegistrationPopup} domain={domain} /> }
                                               </GoogleOAuthProvider>;
        }
    }

    return (
        <div className={`${styles.mainContainer} flex-column container`}>
            { !closedFailedRegistrationPopup ? (<FailedRegistrationPopup onSubmit={tryAgainAction} />) : null }
            { displayWaitingRegistrationPopup ? (<WaitingRegistrationPopup />) : null }
            <div className={styles.imageContainer}>
                <img className={styles.logoImage} loading="lazy" src="/images/Logo.svg" alt="" />
                {activeStep === 3 && (
                    <div className={`${styles.skipButton} flex-row`} onClick={skipOfferStep}>
                        <span className={styles.buttonText}>{strings.registration.skipLink}</span>
                        <img className={styles.skipIcon} loading="lazy" src="/images/icons/gray-arrow-small.svg" alt="" />
                    </div>
                )}
            </div>
            <RegistrationStepper steps={steps} currentStep={activeStep} />
            {stepComponent}
        </div>
    )
}

export default CreateAccount;