import { useEffect } from "react";
import amplitude from 'amplitude-js';
import ContinueButton from "../../UI/continueButton/ContinueButton";
import ResultHeader from "../../UI/resultHeader/ResultHeader";
import styles from "./GoalResult.module.scss";
import strings from "../../../assets/localization/strings.jsx";
import LazyImage from "../../UI/lazyImage/LazyImage";
import VideoFeedbacks from "../../UI/videoFeedbacks/VideoFeedbacks";

const GoalResult = props => {
    const { nextStep, data } = props;
    const pageInfo = data.settings.goalResult;
    const themeData = data.themeData

    useEffect(() => {
        amplitude.getInstance().logEvent('screen_onboarding_section_goal_appeared');
    }, [])

    const clickContinue = () => {
        amplitude.getInstance().logEvent('button_onboarding_section_goal_tapped');
        nextStep();
    }

    const reviewText = pageInfo.cfReviewText ? strings.goal.result.cfReviewText : strings.goal.result.reviewText

    return (
        <div className={`${styles.mainContainer} flex-column`}>
            <ResultHeader
                url={'/images/result/goal-result.jpg'}
                urlWebp={'/images/result/goal-result.webp'}
                theme={themeData?.menuProgressBackground}
            />
            {
                pageInfo.asComment ? (
                    <>
                        { pageInfo.video
                        ? <VideoFeedbacks feedbacks={pageInfo.feedbacks} title={false} name={false} view="square"/>
                        : <LazyImage
                            className={styles.image}
                            srcWebp={pageInfo.resultImageWebp}
                            src={pageInfo.resultImage}
                            alt="" />
                        }
                        <div className={styles.commentContainer}>
                            <div className={styles.titleContainer}>
                                <h2>{strings.goal.result.reviewTitle}</h2>
                                <img loading="lazy" src="/images/stars.svg" alt="" />
                            </div>
                            <p>{reviewText}</p>
                            <div className={`${styles.authorContainer} flex-between`}>
                                <span>{strings.goal.result.reviewAuthor}</span>
                            </div>
                        </div>
                        <ContinueButton
                            text={strings.continue}
                            nextStep={clickContinue}
                            theme={themeData.continueButton}
                        />
                    </>
                ) : (
                    <>
                        <h1>You’re in the right place!</h1>
                        <span className={`${styles.subtitle} componentSubtitle`}>Together we create a dancing journey, that will help you to achieve your goal.</span>
                        <LazyImage
                            className={styles.image}
                            srcWebp="/images/result/goal-image.webp"
                            src="/images/result/goal-image.jpg"
                            alt=""
                        />
                        <p className={`${styles.text} resultSubtitle`}>But first we need to know a little more about you.</p>
                        <ContinueButton
                            text={'All right'}
                            nextStep={clickContinue}
                            theme={themeData.continueButton}
                        />
                    </>
                )
            }
        </div>
    )
}   

export default GoalResult;